(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/team-list-page/assets/javascripts/team-list-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/team-list-page/assets/javascripts/team-list-onboarding.js');
'use strict';

const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const {
  Hero
} = svs.components.marketplace.hero;
const {
  TeamOnboarding
} = svs.marketplace.components.common;
const TeamListOnboarding = () => {
  var _svs$marketplace$data, _svs$marketplace$data2;
  return React.createElement("div", {
    className: "bg-sport-50 marketplace-full-width padding-bottom-2",
    "data-testid": "qa-mkp-team-welcome"
  }, React.createElement(Hero, {
    className: "team-list-hero",
    horizontal: "center",
    image: (_svs$marketplace$data = svs.marketplace.data.heroData) === null || _svs$marketplace$data === void 0 ? void 0 : _svs$marketplace$data.teamHero.image,
    vertical: "middle"
  }, React.createElement("div", {
    className: "marketplace-hero-inner-content"
  }, React.createElement("h2", {
    className: "marketplace-hero-title"
  }, (_svs$marketplace$data2 = svs.marketplace.data.heroData) === null || _svs$marketplace$data2 === void 0 || (_svs$marketplace$data2 = _svs$marketplace$data2.teamHero) === null || _svs$marketplace$data2 === void 0 ? void 0 : _svs$marketplace$data2.title))), React.createElement("div", {
    className: "marketplace-content-wrapper"
  }, React.createElement(TeamOnboarding, null), React.createElement(WidgetArea, {
    className: "marketplace-teams-widget-area",
    widgetArea: "teamsWidgetArea"
  })));
};
setGlobal('svs.marketplace.components.teamListPage.TeamListOnboarding', TeamListOnboarding);

 })(window);